import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Typography,
    Box,
    IconButton,
    Alert,
    Snackbar,
    Checkbox,
    FormControlLabel,
    Link
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { signUpCodeOwner, verifyCodeOwnerOTP, checkEmailExists, loginCodeOwner, requestPasswordReset, resetPassword, verifyResetOtp, getCurrentPrivacyPolicy } from '../services/api';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { config } from '../config';
import { 
    getCurrentToS,
    getToSVersions,
    acceptToS,
    checkToSStatus
} from '../services/api';

export const AuthDialog = ({ 
    embedded = false, 
    onClose, 
    onSuccess, 
    currentStep = 'email',
    onStepChange 
}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [otp, setOtp] = useState('');
    const [isNewUser, setIsNewUser] = useState(false);
    const [showOtpField, setShowOtpField] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [emailExists, setEmailExists] = useState(null);
    const [emailError, setEmailError] = useState('');
    const [otpRequestCount, setOtpRequestCount] = useState(0);
    const [lastOtpRequest, setLastOtpRequest] = useState(null);
    const [canResendOtp, setCanResendOtp] = useState(false);
    const [resendCountdown, setResendCountdown] = useState(30);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [canResendResetOtp, setCanResendResetOtp] = useState(false);
    const [resetOtpCountdown, setResetOtpCountdown] = useState(30);
    const [registrationCompleted, setRegistrationCompleted] = useState(null);
    const [tosAccepted, setTosAccepted] = useState(false);
    const [tosVersion, setTosVersion] = useState(null);
    const [privacyPolicyVersion, setPrivacyPolicyVersion] = useState(null);
    const theme = useTheme();

    // Replace both useEffect hooks with a single one
    useEffect(() => {
        let timer;
        // Handle countdown for both registration and password reset
        if ((showOtpField && resendCountdown > 0) || 
            (isResettingPassword && isOtpSent && resetOtpCountdown > 0)) {
            
            timer = setInterval(() => {
                if (showOtpField) {
                    setResendCountdown(prev => prev - 1);
                    if (resendCountdown === 1) {
                        setCanResendOtp(true);
                    }
                }
                if (isResettingPassword && isOtpSent) {
                    setResetOtpCountdown(prev => prev - 1);
                    if (resetOtpCountdown === 1) {
                        setCanResendResetOtp(true);
                    }
                }
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [
        showOtpField, 
        resendCountdown, 
        isResettingPassword, 
        isOtpSent, 
        resetOtpCountdown
    ]);

    // Keep the validation function for reuse
    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return emailRegex.test(email);
    };

    // Add password validation function
    const validatePassword = (password) => {
        return password.length >= 6;
    };

    const handleEmailBlur = async (e) => {
        console.log('Email blur event triggered');
        
        // Clear previous states
        setEmailError('');
        setEmailExists(null);
        setIsNewUser(false);
        setSuccessMessage('');
        console.log('States cleared');

        const currentEmail = email.trim();
        console.log('Validating email:', currentEmail);

        // First check if email is empty
        if (!currentEmail) {
            console.log('Email is empty');
            setEmailError('Email is required');
            return;
        }

        // Proceed directly to server validation and database check
        setIsLoading(true);
        try {
            console.log('Starting database check for email:', currentEmail);
            const { exists, registrationCompleted: regStatus } = await checkEmailExists(currentEmail);
            console.log('Database check result:', { exists, registrationCompleted: regStatus });
            
            setEmailExists(exists);
            setRegistrationCompleted(regStatus);
            setIsNewUser(!exists);
            
            if (exists) {
                console.log('Email exists in database, checking registration status');
                if (regStatus) {
                    console.log('User is fully registered - showing login form');
                    setShowOtpField(false);
                    setIsResettingPassword(false);
                } else {
                    console.log('Registration incomplete - showing registration form');
                    setShowOtpField(false);
                    setIsNewUser(true);
                }
            } else {
                console.log('New email - ready for registration');
            }
        } catch (err) {
            console.error('Database check error:', err);
            // Handle network errors with user-friendly message
            if (err.message.includes('Connection error')) {
                setEmailError(err.message);  // Use the friendly message from API
            } else if (err.response?.status === 400) {
                setEmailError("This email doesn't seem to be a real one. Please try again.");
            } else {
                setEmailError('Error checking email. Please try again.');
            }
            setEmailExists(false);
            setIsNewUser(false);
        } finally {
            setIsLoading(false);
            console.log('Email validation process completed');
        }
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        if (emailError) setEmailError('');
    };

    // Add password blur handler
    const handlePasswordBlur = (e) => {
        const password = e.target.value;
        if (!validatePassword(password)) {
            setPasswordError('Password must be at least 6 characters long');
        } else {
            setPasswordError('');
        }
    };

    const checkOtpRateLimit = () => {
        const now = Date.now();
        if (lastOtpRequest && (now - lastOtpRequest) < 300000) { // 5 minutes
            throw new Error('Please wait 5 minutes before requesting another OTP');
        }
        if (otpRequestCount >= 3) { // 3 attempts per session
            throw new Error('Too many OTP requests. Please try again later');
        }
        setOtpRequestCount(prev => prev + 1);
        setLastOtpRequest(now);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setPasswordError('');
        setIsLoading(true);

        // Add ToS check for new user registration
        if (isNewUser && !showOtpField && !isResettingPassword && !tosAccepted) {
            setError('Please accept the Terms of Service to continue');
            setIsLoading(false);
            return;
        }

        try {
            if (isNewUser && !showOtpField) {
                // Keep password validation
                if (password !== confirmPassword) {
                    setPasswordError('Passwords do not match');
                    return;
                }
                
                console.log('Submitting acceptance:', {
                    tosVersion,
                    privacyPolicyVersion,
                    email
                });
                // First create the user
                await signUpCodeOwner(email, password);
                // Then accept ToS and Policy
                await acceptToS(email, tosVersion, privacyPolicyVersion);
                setShowOtpField(true);
                setSuccessMessage('Verification code sent to your email');
            } else if (showOtpField) {
                // Registration step 2 - Verify OTP
                const response = await verifyCodeOwnerOTP(email, otp, tosVersion);
                if (response.token) {
                    localStorage.setItem('authToken', response.token);
                    if (onSuccess) {
                        await onSuccess({
                            ...response,
                            email: email  // Add email to response
                        });
                    }
                }
            } else if (isResettingPassword) {
                await checkOtpRateLimit();
                await resetPassword(email, otp, newPassword);
                setSuccessMessage('Password reset successful. Please login.');
                setIsResettingPassword(false);
            } else {
                // Login case
                const response = await loginCodeOwner(email, password);
                console.log('Login response:', response);  // Check what's in the response
                if (response.token) {
                    localStorage.setItem('authToken', response.token);
                    if (onSuccess) {
                        await onSuccess({
                            ...response,
                            email: email  // Explicitly include email
                        });
                    }
                }
            }
        } catch (error) {
            console.error('Auth error:', error);
            if (error.response?.status === 401) {
                setPasswordError("Sorry, we can't recognise your password. Please try again");
            } else {
                setError(error.response?.data?.error || 'An error occurred. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    // These handlers are used in handleSubmit based on different auth states
    // eslint-disable-next-line no-unused-vars
    const handleVerifyOTP = async (event) => {
        event.preventDefault();
        setError('');
        setIsLoading(true);
        
        try {
            const response = await verifyCodeOwnerOTP(email, otp);
            if (response.token) {
                localStorage.setItem('token', response.token);
                localStorage.setItem('userEmail', email);
                setSuccessMessage('Email verified successfully!');
                setTimeout(() => {
                    onSuccess?.();
                    handleClose();
                }, 1500);
            }
        } catch (err) {
            setError(err.response?.data?.message || 'Invalid OTP');
        } finally {
            setIsLoading(false);
        }
    };

    // eslint-disable-next-line no-unused-vars
    const handleResetPassword = async (event) => {
        event.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            if (!newPassword || newPassword.length < 6) {
                throw new Error('New password must be at least 6 characters long');
            }

            if (newPassword !== confirmNewPassword) {
                throw new Error('Passwords do not match');
            }

            // Reset password and get login token
            const response = await resetPassword(email, otp, newPassword);
            
            // If we got a successful response
            if (response.message === "Password reset successful") {
                // Now let's log in with the new credentials
                const loginResponse = await loginCodeOwner(email, newPassword);
                
                if (loginResponse.token) {
                    // Store the token
                    localStorage.setItem('authToken', loginResponse.token);
                    
                    // Show success message briefly
                    setSuccessMessage('Password reset successful!');
                    
                    // Small delay to show success message
                    setTimeout(() => {
                        // Call onAuthSuccess to update parent components (same as login)
                        if (onSuccess) {
                            onSuccess({
                                ...loginResponse,
                                email: email  // Add email to show in welcome message
                            });
                        }
                    }, 1000);
                }
            }
        } catch (err) {
            console.error('Reset password error:', err);
            setError(err.response?.data?.error || 'Failed to reset password');
        } finally {
            setIsLoading(false);
        }
    };

    const handleForgotPassword = async () => {
        try {
            setError('');
            setIsLoading(true);
            await requestPasswordReset(email);
            setIsResettingPassword(true);
            setIsOtpSent(true);
            setOtp('');
            setNewPassword('');
            setConfirmPassword('');
            setCanResendResetOtp(false);
            setResetOtpCountdown(30);
        } catch (error) {
            setError(error.response?.data?.error || 'Failed to send reset code');
        } finally {
            setIsLoading(false);
        }
    };

    const handleOtpChange = (e) => {
        const value = e.target.value;
        setOtp(value);
        setError('');
    };

    const handleVerifyResetOtp = async () => {
        try {
            setIsLoading(true);
            setError('');
            console.log('Attempting to verify OTP:', { email, otp }); // Add logging
            
            const response = await verifyResetOtp(email, otp);
            console.log('Verification response:', response); // Add logging
            
            if (response.verified) {
                setIsOtpVerified(true);
                setError('');
            } else {
                setError('Invalid verification code');
                setIsOtpVerified(false);
            }
        } catch (error) {
            console.error('Verification error:', error);
            setError(error.response?.data?.error || 'Invalid verification code');
            setIsOtpVerified(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setOtp('');
        setError('');
        setSuccessMessage('');
        setIsNewUser(false);
        setShowOtpField(false);
        setIsResettingPassword(false);
        setNewPassword('');
        setConfirmNewPassword('');
        setEmailExists(null);
        setIsLoading(false);
        onClose();
    };

    const handleResendOtp = async () => {
        setError('');
        setIsLoading(true);
        try {
            await checkOtpRateLimit();
            await signUpCodeOwner(email, password);
            setCanResendOtp(false);
            setResendCountdown(30);
            setSuccessMessage('New verification code sent to your email');
        } catch (error) {
            setError(error.message || 'Failed to resend verification code');
        } finally {
            setIsLoading(false);
        }
    };

    // Handle resend password reset OTP
    const handleResendResetOTP = async () => {
        try {
            setError('');
            setIsLoading(true);
            await requestPasswordReset(email);
            setCanResendResetOtp(false);
            setResetOtpCountdown(30);
            setSuccessMessage('New verification code sent to your email');
        } catch (error) {
            console.error('Reset OTP error:', error);
            setError('Something wrong with verification code. Please try again or request new code');
        } finally {
            setIsLoading(false);
        }
    };

    const handleNewPasswordBlur = (e) => {
        const password = e.target.value;
        if (password.length < 6) {
            setError('Password must be at least 6 characters long');
        } else {
            setError('');
        }
    };

    const handleLoginSuccess = async (userData) => {
        try {
            // After successful login
            if (onSuccess) {
                await onSuccess(userData);
            }
            
            // Close the dialog if not embedded
            if (!embedded) {
                onClose();
            }
            
        } catch (error) {
            setError(error.message || 'An error occurred during login');
            setIsLoading(false);
        }
    };

    const handleSignupSuccess = async (userData) => {
        try {
            // After successful signup
            if (onSuccess) {
                await onSuccess(userData);
            }
            
            // Close the dialog if not embedded
            if (!embedded) {
                onClose();
            }
            
        } catch (error) {
            setError(error.message || 'An error occurred during signup');
            setIsLoading(false);
        }
    };

    // Handle email submission and check if user exists
    const handleEmailSubmit = async () => {
        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address");
            return;
        }

        try {
            setIsLoading(true);
            const response = await checkEmailExists(email);
            
            // Update all relevant states
            setEmailExists(response.exists);
            setRegistrationCompleted(response.registrationCompleted);
            setIsNewUser(!response.exists);
            
            // Force step change to password input
            if (onStepChange) {
                onStepChange('password');
            }
            
            // Debug logs
            console.log('Email verification complete:', {
                exists: response.exists,
                registrationCompleted: response.registrationCompleted,
                isNewUser: !response.exists
            });

        } catch (error) {
            setEmailError("Unable to verify email. Please try again.");
            console.error('Email check error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Update getDialogTitle to include console.log for debugging
    const getDialogTitle = () => {
        console.log('getDialogTitle called with:', { emailExists, registrationCompleted });  // Debug log
        if (emailExists === false) {
            return "Create a password for your account";
        }
        return "Welcome back, please enter password to log in";
    };

    // Update useEffect to fetch both versions
    useEffect(() => {
        const fetchVersions = async () => {
            if (isNewUser && !showOtpField && !isResettingPassword) {
                try {
                    // Get ToS version
                    const tosResponse = await getCurrentToS();
                    setTosVersion(tosResponse.version);
                    
                    // Get Privacy Policy version
                    const policyResponse = await getCurrentPrivacyPolicy();
                    setPrivacyPolicyVersion(policyResponse.version);
                } catch (error) {
                    console.error('Failed to fetch document versions:', error);
                }
            }
        };
        fetchVersions();
    }, [isNewUser, showOtpField, isResettingPassword]);

    // Add ToS view handler
    const handleViewToS = async () => {
        try {
            const { content } = await getCurrentToS();
            const newWindow = window.open('', '_blank');
            newWindow.document.write(`
                <html>
                    <head>
                        <title>Terms of Service</title>
                        <style>
                            body { 
                                font-family: Arial, sans-serif;
                                line-height: 1.6;
                                max-width: 800px;
                                margin: 20px auto;
                                padding: 0 20px;
                                color: #333;
                            }
                            h1 {
                                font-size: 1.5em;
                                margin-bottom: 1em;
                                color: #1a1a1a;
                            }
                            h2 {
                                font-size: 1.2em;
                                margin-top: 1.5em;
                                color: #1a1a1a;
                            }
                            p {
                                margin-bottom: 1em;
                            }
                            ul {
                                margin-left: 20px;
                                margin-bottom: 1em;
                            }
                            li {
                                margin-bottom: 0.5em;
                            }
                            .section {
                                margin-bottom: 2em;
                            }
                            .email {
                                color: #0066cc;
                            }
                        </style>
                    </head>
                    <body>
                        ${content.replace(/##/g, '</div><div class="section"><h2>')
                                 .replace(/\*\*/g, '</strong><strong>')
                                 .replace(/\[/g, '<span class="email">')
                                 .replace(/\]/g, '</span>')
                                 .replace(/\n/g, '<br>')}
                    </body>
                </html>
            `);
        } catch (error) {
            setError('Failed to load Terms of Service');
        }
    };

    // Update the Privacy Policy handler to use the new endpoint
    const handleViewPrivacyPolicy = async () => {
        try {
            const { content } = await getCurrentPrivacyPolicy();
            const newWindow = window.open('', '_blank');
            newWindow.document.write(`
                <html>
                    <head>
                        <title>Privacy Policy</title>
                        <style>
                            body { 
                                font-family: Arial, sans-serif;
                                line-height: 1.6;
                                max-width: 800px;
                                margin: 20px auto;
                                padding: 0 20px;
                                color: #333;
                            }
                            h1 {
                                font-size: 1.5em;
                                margin-bottom: 1em;
                                color: #1a1a1a;
                            }
                            h2 {
                                font-size: 1.2em;
                                margin-top: 1.5em;
                                color: #1a1a1a;
                            }
                            p {
                                margin-bottom: 1em;
                            }
                            ul {
                                margin-left: 20px;
                                margin-bottom: 1em;
                            }
                            li {
                                margin-bottom: 0.5em;
                            }
                            .section {
                                margin-bottom: 2em;
                            }
                            .email {
                                color: #0066cc;
                            }
                        </style>
                    </head>
                    <body>
                        ${content.replace(/##/g, '</div><div class="section"><h2>')
                                 .replace(/\*\*/g, '</strong><strong>')
                                 .replace(/\[/g, '<span class="email">')
                                 .replace(/\]/g, '</span>')
                                 .replace(/\n/g, '<br>')}
                    </body>
                </html>
            `);
        } catch (error) {
            console.error('Failed to load Privacy Policy:', error);
            setError('Failed to load Privacy Policy');
        }
    };

    // Single render function for all states
    const renderContent = () => {
        return (
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                px: 0,
                pt: 0
            }}>
                {/* Email field - always visible */}
                <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    error={!!emailError}
                    helperText={emailError}
                    disabled={isLoading}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '56px',
                            backgroundColor: theme.palette.input.background,
                            '&:hover': {
                                backgroundColor: theme.palette.input.activeBackground
                            },
                            '&.Mui-focused': {
                                backgroundColor: theme.palette.input.activeBackground
                            }
                        }
                    }}
                />

                {emailExists === true && !isResettingPassword ? (
                    // Login form
                    <>
                        <Typography 
                            variant="body1" 
                            align="left"
                            sx={{
                                fontSize: '0.875rem',
                                color: passwordError ? theme.palette.error.main : theme.palette.text.secondary,
                                mt: 2,
                                mb: 2,
                                ml: 1
                            }}
                        >
                            {passwordError || "Welcome back, please enter password to log in"}
                        </Typography>

                        <TextField
                            fullWidth
                            label="Password"
                            type="password"
                            value={password}
                            autoComplete="current-password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setPasswordError('');
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '56px'
                                }
                            }}
                        />

                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={isLoading}
                            sx={{
                                height: '56px',
                                backgroundColor: theme.palette.button.main,
                                color: theme.palette.button.contrastText,
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                mt: 2
                            }}
                        >
                            Sign In
                        </Button>

                        <Button
                            onClick={handleForgotPassword}
                            color="primary"
                            variant="text"
                            sx={{ mt: 1 }}
                            fullWidth
                        >
                            Forgot Password?
                        </Button>
                    </>
                ) : isResettingPassword ? (
                    // Password Reset Flow
                    <>
                        <Typography 
                            variant="body1" 
                            align="left"
                            sx={{
                                fontSize: '0.875rem',
                                color: successMessage 
                                    ? theme.palette.success.main 
                                    : error 
                                        ? theme.palette.error.main 
                                        : theme.palette.text.secondary,
                                mt: 2,
                                mb: 2,
                                ml: 1
                            }}
                        >
                            {successMessage 
                                ? successMessage 
                                : !isOtpSent 
                                    ? "We'll send a verification code to your email" 
                                    : isOtpVerified
                                        ? "Create your new password"
                                        : error 
                                            ? "Something wrong with verification code. Please try again or request new code"
                                            : "Enter verification code sent to your email"
                            }
                        </Typography>

                        {isOtpSent && !isOtpVerified ? (
                            // OTP verification UI - existing code
                            <>
                                <TextField
                                    fullWidth
                                    label="Verification Code"
                                    value={otp}
                                    onChange={(e) => {
                                        setOtp(e.target.value);
                                        setError('');
                                    }}
                                    error={!!error}
                                    autoComplete="off"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '56px'
                                        }
                                    }}
                                />

                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={handleVerifyResetOtp}
                                    disabled={isLoading || !otp}
                                    sx={{
                                        height: '56px',
                                        backgroundColor: theme.palette.button.main,
                                        color: theme.palette.button.contrastText,
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        mt: 2
                                    }}
                                >
                                    Verify Code
                                </Button>

                                {/* Resend code section */}
                                {canResendResetOtp && (
                                    <Button
                                        color="primary"
                                        variant="text"
                                        onClick={handleResendResetOTP}
                                        sx={{ mt: 1 }}
                                        fullWidth
                                    >
                                        Resend Code
                                    </Button>
                                )}

                                {!canResendResetOtp && (
                                    <Typography 
                                        variant="body2" 
                                        align="center"
                                        sx={{ mt: 1, color: theme.palette.text.secondary }}
                                    >
                                        Resend code in {resetOtpCountdown}s
                                    </Typography>
                                )}
                            </>
                        ) : isOtpVerified ? (
                            // New password fields after OTP verification
                            <>
                                <TextField
                                    fullWidth
                                    label="New Password"
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    autoComplete="new-password"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '56px'
                                        },
                                        mb: 2
                                    }}
                                />

                                <TextField
                                    fullWidth
                                    label="Confirm New Password"
                                    type="password"
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                    error={newPassword !== confirmNewPassword && confirmNewPassword !== ''}
                                    helperText={newPassword !== confirmNewPassword && confirmNewPassword !== '' ? 
                                        'Passwords do not match' : ''}
                                    autoComplete="new-password"
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            height: '56px'
                                        }
                                    }}
                                />

                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={handleResetPassword}
                                    disabled={isLoading || !newPassword || !confirmNewPassword || newPassword !== confirmNewPassword}
                                    sx={{
                                        height: '56px',
                                        backgroundColor: theme.palette.button.main,
                                        color: theme.palette.button.contrastText,
                                        fontSize: '1rem',
                                        fontWeight: 'bold',
                                        mt: 2
                                    }}
                                >
                                    Reset Password
                                </Button>
                            </>
                        ) : (
                            // Initial reset password button
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handleForgotPassword}
                                disabled={isLoading}
                                sx={{
                                    height: '56px',
                                    backgroundColor: theme.palette.button.main,
                                    color: theme.palette.button.contrastText,
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                    mt: 2
                                }}
                            >
                                Send Reset Code
                            </Button>
                        )}

                        <Button
                            color="primary"
                            variant="text"
                            onClick={() => {
                                setIsResettingPassword(false);
                                setIsOtpVerified(false);
                                setError('');
                                setOtp('');
                                setNewPassword('');
                                setConfirmNewPassword('');
                            }}
                            sx={{ mt: 1 }}
                            fullWidth
                        >
                            Back to Login
                        </Button>
                    </>
                ) : isNewUser && !showOtpField && emailExists === false ? (
                    <>
                        <Typography 
                            variant="body1" 
                            align="left"
                            sx={{
                                fontSize: '0.875rem',
                                color: theme.palette.text.secondary,
                                mt: 2,
                                mb: 1,
                                ml: 1
                            }}
                        >
                            {getDialogTitle()}
                        </Typography>

                        <TextField
                            fullWidth
                            label="Password"
                            type="password"
                            value={password}
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={handlePasswordBlur}
                            error={!!passwordError}
                            helperText={passwordError}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '56px'
                                },
                                mb: 2
                            }}
                        />

                        <TextField
                            fullWidth
                            label="Confirm Password"
                            type="password"
                            value={confirmPassword}
                            autoComplete="new-password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={password !== confirmPassword && confirmPassword !== ''}
                            helperText={password !== confirmPassword && confirmPassword !== '' ? 
                                'Passwords do not match' : ''}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '56px'
                                },
                                mb: 2
                            }}
                        />

                        {/* Add ToS acceptance checkbox */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={tosAccepted}
                                    onChange={(e) => setTosAccepted(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label={
                                <Box component="span" sx={{ fontSize: '0.875rem' }}>
                                    I accept the{' '}
                                    <Link
                                        component="button"
                                        type="button"
                                        onClick={handleViewToS}
                                        sx={{ 
                                            textDecoration: 'underline',
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        Terms of Service v{tosVersion}
                                    </Link>
                                    {' '}and{' '}
                                    <Link
                                        component="button"
                                        type="button"
                                        onClick={handleViewPrivacyPolicy}
                                        sx={{ 
                                            textDecoration: 'underline',
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        Privacy Policy v{privacyPolicyVersion}
                                    </Link>
                                </Box>
                            }
                            sx={{ mb: 2 }}
                        />

                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={isLoading || !password || !confirmPassword || 
                                     password !== confirmPassword || !tosAccepted}
                            sx={{
                                height: '56px',
                                backgroundColor: theme.palette.button.main,
                                color: theme.palette.button.contrastText,
                                fontSize: '1rem',
                                fontWeight: 'bold'
                            }}
                        >
                            Create Account
                        </Button>
                    </>
                ) : showOtpField ? (
                    // OTP verification step - using existing flow
                    <>
                        <Typography 
                            variant="body1" 
                            align="left"
                            sx={{
                                fontSize: '0.875rem',
                                color: theme.palette.text.secondary,
                                mt: 2,
                                mb: 1,
                                ml: 1
                            }}
                        >
                            Enter verification code sent to your email
                        </Typography>

                        <TextField
                            fullWidth
                            label="Verification Code"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                            error={!!error}
                            autoComplete="off"
                            helperText={error}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    height: '56px'
                                }
                            }}
                        />

                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={isLoading || !otp}
                            sx={{
                                height: '56px',
                                backgroundColor: theme.palette.button.main,
                                color: theme.palette.button.contrastText,
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                mt: 2
                            }}
                        >
                            Verify Code
                        </Button>

                        {canResendOtp && (
                            <Button
                                color="primary"
                                variant="text"
                                onClick={handleResendOtp}
                                disabled={!canResendOtp}
                                sx={{ mt: 1 }}
                                fullWidth
                            >
                                Resend Code
                            </Button>
                        )}

                        {!canResendOtp && (
                            <Typography 
                                variant="body2" 
                                align="center"
                                sx={{ mt: 1, color: theme.palette.text.secondary }}
                            >
                                Resend code in {resendCountdown}s
                            </Typography>
                        )}
                    </>
                ) : (
                    // Initial continue button
                    !emailExists && (
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleEmailSubmit}
                            disabled={isLoading}
                            sx={{
                                height: '56px',
                                backgroundColor: theme.palette.button.main,
                                color: theme.palette.button.contrastText,
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                mt: 4
                            }}
                        >
                            CONTINUE
                        </Button>
                    )
                )}

                {/* Show any error messages */}
                {error && !isResettingPassword && (
                    <Typography 
                        color="error" 
                        sx={{ mt: 2, textAlign: 'center' }}
                    >
                        {error}
                    </Typography>
                )}
            </Box>
        );
    };

    return renderContent();
}; 